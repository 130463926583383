import { render, staticRenderFns } from "./LihatHarga.vue?vue&type=template&id=17bb5d90&scoped=true&"
import script from "./LihatHarga.vue?vue&type=script&lang=js&"
export * from "./LihatHarga.vue?vue&type=script&lang=js&"
import style0 from "./LihatHarga.vue?vue&type=style&index=0&id=17bb5d90&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "17bb5d90",
  null
  
)

export default component.exports